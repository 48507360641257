import React from 'react';
import PropTypes from 'prop-types';
import MillisFormatter from 'pretty-ms';
import { getHeatPopulationData } from '../../api/data.api';
import { MacDialogStyles } from './macdialog.style';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CssBaseline from '@material-ui/core/CssBaseline';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fade from '@material-ui/core/Fade';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

class MacDialog extends React.Component {

    state = {};

    constructor(props) {
        super(props);
        this.state = {
            progressEnabled: false,
            isOpen: props.isOpen,
            macAddresses: [],
            locations: props.locations,
            macAddressesSize: '',
            picker: props.picker,
            selectedTime: props.selectedTime,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isOpen !== this.props.isOpen)
            this.setState({ isOpen: this.props.isOpen });
        if (prevProps.picker !== this.props.picker)
            this.setState({ picker: this.props.picker });
        if (prevProps.selectedTime !== this.props.selectedTime)
            this.setState({ selectedTime: this.props.selectedTime });
        if (prevProps.locations !== this.props.locations)
            this.setState({ locations: this.props.locations });
    }

    loadMACs = () => {
        this.setState({ progressEnabled: true });

        let locationIds = [];
        for(let i = 0; i < this.state.locations.length; i++)
            if (this.state.locations[i].selected)
                locationIds.push(this.state.locations[i].sensorId);

        getHeatPopulationData(
            this.state.selectedTime,
            this.state.picker.density,
            locationIds,
            this.state.picker.includeVirtual
        ).then(response => {
            var macs = [];
            if (response !== undefined && response !== null && response.errorCode === 0)
                for (var i = 0; i < response.results.length; i++)
                    macs.push({
                        id: response.results[i].deviceId,
                        title: response.results[i].mac,
                        duration: response.results[i].duration > 0 ? MillisFormatter(response.results[i].duration * 1000) : 'just started',
                        checked: true
                    });

            this.setState({ macAddresses: macs, macAddressesSize: "(" + macs.length + ")" }, () => this.setState({ progressEnabled: false }));
        });
    }

    onItemClick = (index) => {
        const updatedMacs = [...this.state.macAddresses];
        updatedMacs[index].checked = !updatedMacs[index].checked;
        this.setState({ macAddresses: updatedMacs });
    }

    onDialogCloseSuccess = () => {
        this.props.onSuccess(this.state.macAddresses);
        this.setState({ isOpen: false, macAddresses: [], macAddressesSize: '' });
    }

    onDialogCloseCancelled = () => {
        this.props.onCancelled();
        this.setState({ isOpen: false, macAddresses: [], macAddressesSize: '' });
    }

    render() {
        const { classes } = this.props;

        return (
            <div>
                <CssBaseline />

                <Dialog classes={{ paper: classes.windowSize }} aria-labelledby="simple-dialog-title" open={this.state.isOpen} onEntered={this.loadMACs} onClose={this.onDialogCloseCancelled}>
                    <Fade in={this.state.progressEnabled} style={{ transitionDelay: this.state.progressEnabled ? '100ms' : '0ms' }}>
                        <LinearProgress color="primary" className={classes.progress} />
                    </Fade>

                    <DialogTitle id="simple-dialog-title">
                        <b>Filter selected MAC population</b>{" " + this.state.macAddressesSize}
                    </DialogTitle>

                    <DialogContent className={classes.contentPaddings}>
                        <List>
                            {this.state.macAddresses.map((mac, index) => (
                                <ListItem button onClick={() => this.onItemClick(index)} key={mac.id}>
                                    <Checkbox checked={mac.checked} />
                                    <ListItemText primary={
                                        <Typography align='left'><b>{mac.title}</b></Typography>
                                    } />
                                    <ListItemText primary={
                                        <Typography align='right' color='primary'>{mac.duration}</Typography>
                                    } className={classes.itemSubtitle} />
                                </ListItem>
                            ))}
                        </List>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={() => this.onDialogCloseSuccess()} color="primary">Update Population</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

MacDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onCancelled: PropTypes.func.isRequired,
    picker: PropTypes.object.isRequired,
    selectedTime: PropTypes.number,
    locations: PropTypes.array.isRequired
};

export default withStyles(MacDialogStyles)(MacDialog);
