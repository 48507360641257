export const PickerBoxStyles = theme => ({
    root: {
        display: 'flex'
    },
    settingsTopMargin: {
        marginTop: 24
    },
    settingsBottomMargin: {
        marginBottom: 24
    },
    snackbarIcon: {
        fontSize: 20,
    },
    snackbarIconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    snackbarMessage: {
        display: 'flex',
        alignItems: 'center',
    },
    pickerDefaults: {
        margin: "6px",
        width: "270px"
    }
});