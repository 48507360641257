export const MapPickerStyles = theme => ({
    map: {
        marginTop: '64px',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            height: `calc(100% - 56px)`
        },
        [theme.breakpoints.up('md')]: {
            height: `calc(100% - 64px)`,
        }
    }
});
