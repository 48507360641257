import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import CodeIcon from '@material-ui/icons/Code';
import DomainIcon from '@material-ui/icons/Domain';
import SchoolIcon from '@material-ui/icons/School';
import InfoIcon from '@material-ui/icons/Info';

export const mainListItems = (
  <div>
    <ListItem button>
      <ListItemIcon>
        <EqualizerIcon />
      </ListItemIcon>
      <ListItemText primary="Mobintel Plots" secondary="Clematis Street, WPB" />
    </ListItem>
    <ListItem button component="a" href="https://about.mobintel.org" target="_blank">
      <ListItemIcon>
        <InfoIcon />
      </ListItemIcon>
      <ListItemText primary="About Us" secondary="Learn about the project" />
    </ListItem>
  </div>
);

export const secondaryListItems = (
  <div>
    <ListItem button component="a" href="https://github.com/i-sense/mobintel" target="_blank">
      <ListItemIcon>
        <CodeIcon />
      </ListItemIcon>
      <ListItemText primary="GitHub Repo" secondary="Restricted access" />
    </ListItem>
    <ListItem button component="a" href="https://isense.fau.edu" target="_blank">
      <ListItemIcon>
        <DomainIcon />
      </ListItemIcon>
      <ListItemText primary="I-SENSE" secondary="Visit our Lab website" />
    </ListItem>
    <ListItem button component="a" href="https://fau.edu" target="_blank">
      <ListItemIcon>
        <SchoolIcon />
      </ListItemIcon>
      <ListItemText primary="Florida Atlantic University" secondary="Visit FAU's website" />
    </ListItem>
  </div>
);