import React from 'react';
import { Area, AreaChart, CartesianGrid, Label, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import PropTypes from 'prop-types';
import moment from 'moment';
import DefaultTooltipContent from 'recharts/lib/component/DefaultTooltipContent';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const CustomTooltip  = (props) => {
  const propTypes = {
    type: PropTypes.string,
    payload: PropTypes.array,
    label: PropTypes.string,
  };

  const { active } = props;

  if (active) {
    const { payload, label } = props;
    try {
      const time = payload[0].payload.time;
      if (timestampIsWholeHour(time)) {
        return <DefaultTooltipContent {...props}/>;
      }
    }
    catch (error) {
      return null;
    }

  }

  return null;
};

/**
 * props:
 *  heatPlotApiResponse,
 *  xAxisTitle,
 *  yAxisTitle
 */
class Chart extends React.Component {

  state = {
    data: []
  }

  constructor(props) {
    super(props);
    this.state = {
      data: []
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.apiResponse && this.props.apiResponse !== prevProps.apiResponse)
      this.generateData(this.props.apiResponse.results);
  }

  generateData = (apiResponse) => {
    let data = apiResponse.map((dataElement) => {
      return { time: dataElement[0], amountOriginal: dataElement[1], amountComparison: dataElement[2] };
    });

    this.setState({ data });
  }

  onChartClick = (props) => {
    if (!props) return;
    const timestamp = props.activeLabel;
    if (this.props.onChartClick)
      this.props.onChartClick(timestamp);
  }

  render() {
    return (
      <React.Fragment>
        {
          this.state.data.length || this.props.loading ?
          <ResponsiveContainer height={350}>
          <AreaChart data={this.state.data}
            onClick={this.onChartClick}
            margin={{ top: 16, right: 48, bottom: 0, left: 48 }}>
            <defs>
              <linearGradient id="colorOriginal" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#FF9800" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#FF9800" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorML" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#3F51B5" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#3F51B5" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis dataKey="time" tickFormatter={(unixTime) => moment(unixTime).format('HH:mm Do')} />
            <YAxis>
              <Label angle={270} position="left" style={{ textAnchor: 'middle' }}>
                {this.props.yAxisTitle}
              </Label>
            </YAxis>
            <CartesianGrid strokeDasharray="3 3" />
            {
              this.props.customTooltip ? 
              <Tooltip content={<CustomTooltip 
                labelFormatter={(value) => { return 'Time: ' + moment(value).format('MMMM Do YYYY, h:mm:ss a') }}
                formatter={(value) => { return value + this.props.yAxisAppendix }} />}/>
              :
              <Tooltip
                labelFormatter={(value) => { return 'Time: ' + moment(value).format('MMMM Do YYYY, h:mm:ss a') }}
                formatter={(value) => { return value + this.props.yAxisAppendix }} />
            }
            
            <Area type="monotone" dataKey="amountOriginal" stroke="#FF9800" fillOpacity={1} fill="url(#colorOriginal)" />
            <Area type="monotone" dataKey="amountComparison" stroke="#3F51B5" fillOpacity={1} fill="url(#colorML)" />
          </AreaChart>
        </ResponsiveContainer>
        :
        <Box p={2} >
           <Typography variant="h6" component="h5" align="center" style={{color: '#a6a6a6'}}>
            A plot is not available for this timestamp. Please try later.
          </Typography>
        </Box>
        }
      </React.Fragment>
    );
  }
}

export const ONE_HOUR = 60 * 60 * 1000; /* ms */
export const timestampIsWholeHour = (timestamp) => {
  return timestamp % 3600000 === 0 && (Date.now() - timestamp) > ONE_HOUR;
}

Chart.propTypes = {
  apiResponse: PropTypes.object,
  yAxisTitle: PropTypes.string.isRequired,
  yAxisAppendix: PropTypes.string.isRequired,
};

export default Chart;
