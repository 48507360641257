import React from 'react';
import PropTypes from 'prop-types';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import withUnmounted from '@ishawnwang/withunmounted';
import { MapPickerStyles } from './mappicker.style';
import { withStyles } from '@material-ui/core/styles';

export class MapPicker extends React.Component {

    hasUnmounted = false;
    state = {};

    constructor(props) {
        super(props);
        this.state = { locations: props.locations };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.locations !== this.props.locations)
            this.setState({ locations: this.props.locations });
    }

    onMarkerClick = (position) => {
        // copying the list of locations from the filtered list & updating the selected item's status
        const updatedLocations = this.state.locations;
        for(let i = 0; i < updatedLocations.length; i++)
            if (updatedLocations[i].selected)
                updatedLocations[i].selected = false;
        updatedLocations[position].selected = !updatedLocations[position].selected;

        // updating the state
        this.setState({ locations: updatedLocations });

        this.props.onLocationUnselected(updatedLocations);
    };

    render() {
        const { google } = this.props;
        return (
            <Map
                style={{
                    marginTop: '64px',
                    width: '100%',
                    height: `calc(100% - 64px)`
                }}
                google={this.props.google}
                initialCenter={{ lat: 26.713412, lng: -80.053584 }}
                zoom={18}>

                {this.state.locations.map((location, position) => {
                    return (
                        <Marker
                            icon={{
                                url: location.selected ? "/marker.png" : "/marker_inactive.png",
                                anchor: new google.maps.Point(24, 24),
                                scaledSize: new google.maps.Size(34, 34)
                            }}
                            key={location.sensorId}
                            name={location.name}
                            onClick={this.onMarkerClick.bind(this, position)}
                            position={{ lat: location.location.x, lng: location.location.y }} />
                    )
                })}
            </Map>
        );
    }
};

MapPicker.propTypes = {
    classes: PropTypes.object.isRequired,
    locations: PropTypes.array.isRequired,
    onLocationUnselected: PropTypes.func.isRequired
};

export default withStyles(MapPickerStyles)(GoogleApiWrapper({
    apiKey: ("AIzaSyAFjJ5fDj8Se3xC8T4PGVBB7cFinvq_K5A")
})(withUnmounted(MapPicker)));
