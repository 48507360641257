import React from 'react';
import clsx from 'clsx';
import Chart, { timestampIsWholeHour } from '../chart/chart.component';
import PickerBox from '../pickerbox/pickerbox.component';
import MacDialog from '../macdialog/macdialog.component';
import MapPicker from '../mappicker/mappicker.component';
import MapPickerV2 from '../mappicker-v2/mappicker-v2.component';
import ChipPanel from '../chippanel/chippanel.component';
import Title from '../title/title.component';
import PropTypes from 'prop-types';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import MailIcon from '@material-ui/icons/Mail';
import MapIcon from '@material-ui/icons/Map';
import InfoIcon from '@material-ui/icons/Info';
import withUnmounted from '@ishawnwang/withunmounted';
import { DashboardStyles } from './dashboard.style';
import { getHeatData, getRetentionData, getSensors } from '../../api/data.api';
import { mainListItems, secondaryListItems } from '../drawer/drawer.component';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Fab from '@material-ui/core/Fab';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Heatmap from "../heatmap/heatmap.component";

Array.prototype.naturalSort = function(){
    let a, b, a1, b1, rx =/(\d+)|(\D+)/g, rd=/\d+/;
    return this.sort(function(as, bs){
        a = String(as.label).toLowerCase().match(rx);
        b = String(bs.label).toLowerCase().match(rx);
        while(a.length && b.length){
            a1 = a.shift();
            b1 = b.shift();
            if(rd.test(a1) || rd.test(b1)){
                if(!rd.test(a1)) return 1;
                if(!rd.test(b1)) return -1;
                if(a1 != b1) return a1-b1;
            }
            else if(a1 != b1) return a1> b1? 1: -1;
        }
        return a.length- b.length;
    });
};


class Dashboard extends React.Component {

    hasUnmounted = false;
    state = {}

    constructor(props) {
        let date = new Date();
        date.setHours(date.getHours() - 48, 0, 0, 0);
        const dateWeekAgo = new Date();
        dateWeekAgo.setDate(dateWeekAgo.getDate() - 7);
        dateWeekAgo.setHours(0, 0, 0, 0);
        const endOfDayToday = new Date();
        endOfDayToday.setHours(23, 59, 59, 999);
        super(props);
        this.state = {
            drawerOpen: false,
            mapOpen: false,
            filteringDevices: [],
            heatPicker: {
                density: 60,
                includeVirtual: true,
                startDate: new Date(),
                endDate: new Date(),
                densityBlocked: false
            },
            retentionPicker: {
                density: 60,
                includeVirtual: true,
                startDate: date,
                endDate: new Date(),
                densityBlocked: false
            },
            heatmapPicker: {
                density: 60,
                includeVirtual: true,
                startDate: dateWeekAgo,
                endDate: endOfDayToday,
                densityBlocked: true,
                strictPeriodLengthInDays: 7,
            },
            heatPlotApiResponse: {},
            retentionPlotApiResponse: {},
            heatmapPlotApiResponse: {},
            chipsProgressEnabled: false,
            heatProgressEnabled: false,
            retentionProgressEnabled: false,
            heatmapPlotProgressEnabled: false,
            macModalOpen: false,
            resetFiltersEnabled: false,
            locations: [],
            comparisonModeEnabled: false
        };
        this.state.heatPicker.startDate.setHours(this.state.heatPicker.startDate.getHours() - 48);
        // this.state.retentionPicker.startDate.setHours(this.state.retentionPicker.startDate.getHours() - 48);

        this.handleSensorIdSelectChange = this.handleSensorIdSelectChange.bind(this);
    }

    componentDidMount = () => {
        this.retrieveSensors().then(() => {
            this.retrieveAllData();
        });
    };

    drawerDidOpen = () => {
        this.setState({
            drawerOpen: true
        })
    }

    drawerDidClose = () => {
        this.setState({
            drawerOpen: false
        })
    }

    onHeatChartClick = (timestamp) => {
        if (!timestampIsWholeHour(timestamp)){
            let time = new Date(timestamp);
            time.setHours(time.getHours(), 0, 0, 0);
            timestamp = time.getTime();
        }
        this.onResetFiltersClick();
        // TODO: TEMPORARY!!!!! (update retention graph on heat graph click)
        this.setState({
            retentionPicker: {
                startDate: new Date(timestamp),
                endDate: this.state.retentionPicker.endDate,
                density: this.state.retentionPicker.density,
                includeVirtual: this.state.retentionPicker.includeVirtual,
                densityBlocked: this.state.retentionPicker.densityBlocked
            }
        }, () => {
            this.retrieveRetentionData();
        });
        /*this.setState({ macModalOpen: true, selectedPopulationDate: timestamp });*/
    };

    onResetFiltersClick = () => {
        if (this.state.filteringDevices && this.state.filteringDevices.length > 0)
            this.setState({
                filteringDevices: [],
                retentionPicker: {
                    density: this.state.retentionPicker.density,
                    includeVirtual: this.state.retentionPicker.includeVirtual,
                    startDate: this.state.heatPicker.startDate, // <-- the concept is that we reset retention with a heat plot starting date
                    endDate: this.state.retentionPicker.endDate,
                    densityBlocked: this.state.retentionPicker.densityBlocked
                },
                resetFiltersEnabled: false
            }, () => {
                this.retrieveAllData();
            });
    }

    getSelectedLocationIds() {
        const ids = [];
        for (var i = 0; i < this.state.locations.length; i++)
            if (this.state.locations[i].selected)
                ids.push(this.state.locations[i].sensorId);
        return ids;
    }

    //#region Picker Callbacks

    heatPickerDidUpdate = (densityMinutes, includeVirtual, startTimeMillis, endTimeMillis) => {
        this.setState({
            filteringDevices: this.state.filteringDevices,
            heatPicker: {
                startDate: startTimeMillis,
                endDate: endTimeMillis,
                density: densityMinutes,
                includeVirtual: includeVirtual,
                densityBlocked: this.state.heatPicker.densityBlocked
            }
        }, () => this.retrieveHeatData());
    }

    retentionPickerDidUpdate = (densityMinutes, includeVirtual, startTimeMillis, endTimeMillis) => {
        this.setState({
            retentionPicker: {
                startDate: startTimeMillis,
                endDate: endTimeMillis,
                density: densityMinutes,
                includeVirtual: includeVirtual,
                densityBlocked: this.state.retentionPicker.densityBlocked
            }
        }, () => {
            this.retrieveRetentionData();
        });
    };

    heatmapPickerDidUpdate = (densityMinutes, includeVirtual, startDate) => {
        startDate.setHours(0, 0, 0, 0);
        const endDate = new Date(startDate);
        endDate.setDate(endDate.getDate() + 6);
        endDate.setHours(23, 59, 59, 999);
        this.setState({
            heatmapPicker: {
                ...this.state.heatmapPicker,
                startDate: startDate,
                endDate: endDate,
                density: densityMinutes,
                includeVirtual: includeVirtual,
            }
        }, () => {
            this.retrieveHeatmapPlotData();
        });
    };

    //#endregion

    //#region Dialog Callbacks

    onDialogClosedSuccess = (updatedMacAddresses) => {
        const updatedFilteringDevices = [];

        for (var i = 0; i < updatedMacAddresses.length; i++)
            if (!updatedMacAddresses[i].checked)
                updatedFilteringDevices.push(updatedMacAddresses[i].id);

        this.setState({
            macModalOpen: false,
            resetFiltersEnabled: updatedFilteringDevices.length !== 0,
            filteringDevices: updatedFilteringDevices,
            retentionPicker: {
                density: this.state.heatPicker.density,
                includeVirtual: this.state.heatPicker.includeVirtual,
                startDate: new Date(this.state.selectedPopulationDate),
                endDate: this.state.heatPicker.endDate,
                densityBlocked: this.state.retentionPicker.densityBlocked
            }
        }, () => {
            this.retrieveAllData();
        });
    }

    onDialogClosedFailure = () => {
        this.setState({macModalOpen: false});
    }

    //#endregion

    //#region Map Drawer Callbacks

    onMapToggle = () => {
        this.setState({mapOpen: !this.state.mapOpen});
    };

    onMapLocationUnselected = (updatedLocations) => {
        this.setState({locations: updatedLocations}, () => {
            this.retrieveAllData();
        });
    }

    //#endregion

    //#region Chip Panel Callbacks

    onChipUnselected = (updatedLocations) => {
        this.setState({locations: updatedLocations}, () => {
            this.retrieveAllData();
        });
    };

    onChipAdd = () => {
        this.setState({mapOpen: true});
    };

    //#endregion

    //#region Comparison Mode Callbacks

    onModeSwitchUpdated = () => {
        let updatedPickerValues = {
            includeVirtual: this.state.heatPicker.includeVirtual,
            startDate: this.state.heatPicker.startDate,
            endDate: this.state.heatPicker.endDate
        };

        if (this.state.comparisonModeEnabled) {
            updatedPickerValues.density = this.state.heatPicker.density;
            updatedPickerValues.densityBlocked = false;
        } else {
            updatedPickerValues.density = 60;
            updatedPickerValues.densityBlocked = true;

            updatedPickerValues.startDate.setMinutes(0);
            updatedPickerValues.startDate.setSeconds(0);
            updatedPickerValues.startDate.setMilliseconds(0);

            updatedPickerValues.endDate.setMinutes(1);
            updatedPickerValues.endDate.setSeconds(0);
            updatedPickerValues.endDate.setMilliseconds(0);
        }

        this.setState({
            heatPicker: updatedPickerValues,
            comparisonModeEnabled: !this.state.comparisonModeEnabled
        }, () => this.retrieveHeatData());
    }

    //#endregion

    //#region API Calls

    retrieveSensors = () => {
        this.setState({chipsProgressEnabled: true});
        return getSensors().then(response => {
            if (this.hasUnmounted) return;
            else {
                if (response.results && response.results.length > 0)
                    response.results[0].selected = true;
                let locations = response.results;
                locations.naturalSort();
                locations = locations.map(location => ({
                    ...location,
                    selected: location.sensorId === 1,
                }));
                this.setState({locations, chipsProgressEnabled: false});
            }
        });
    };

    retrieveAllData() {
        this.retrieveHeatData();
        this.retrieveRetentionData();
        this.retrieveHeatmapPlotData();
    }

    retrieveHeatData = () => {
        this.setState({heatProgressEnabled: true});
        const locationIds = this.getSelectedLocationIds();
        const sensorId = locationIds.length > 0 ? locationIds[0] : undefined;
        if (sensorId !== undefined || sensorId) {
            getHeatData(
                this.state.heatPicker.startDate.getTime(),
                this.state.heatPicker.endDate.getTime(),
                this.state.heatPicker.density * 60 * 1000,
                sensorId,
                this.state.heatPicker.includeVirtual,
                this.state.comparisonModeEnabled).then(response => {
                if (this.hasUnmounted)
                    return;
                else this.setState({heatPlotApiResponse: response, heatProgressEnabled: false})
            });
        }
    };

    retrieveRetentionData = () => {
        this.setState({retentionProgressEnabled: true});
        const locationIds = this.getSelectedLocationIds();
        if (locationIds !== undefined && locationIds.length > 0) {
            getRetentionData(
                locationIds,
                this.state.retentionPicker.startDate.getTime(),
                this.state.retentionPicker.includeVirtual).then(response => {
                if (this.hasUnmounted)
                    return;
                else this.setState({retentionPlotApiResponse: response, retentionProgressEnabled: false})
            }).catch(reqError => {
                console.log('error retention data request: ', reqError)
                this.setState({retentionProgressEnabled: false});
            });
        }
    };

    retrieveHeatmapPlotData = () => {
        this.setState({heatmapPlotProgressEnabled: true});
        const locationIds = this.getSelectedLocationIds();
        const sensorId = locationIds.length > 0 ? locationIds[0] : undefined;
        if (sensorId !== undefined || sensorId) {
            getHeatData(
                this.state.heatmapPicker.startDate.getTime(),
                this.state.heatmapPicker.endDate.getTime(),
                this.state.heatmapPicker.density * 60 * 1000,
                sensorId,
                this.state.heatmapPicker.includeVirtual,
                this.state.comparisonModeEnabled
            ).then(response => {
                if (this.hasUnmounted)
                    return;
                else this.setState({heatmapPlotApiResponse: response, heatmapPlotProgressEnabled: false})
            }).catch(reqError => {
                console.log('error heatmap data request: ', reqError);
                this.setState({heatmapPlotProgressEnabled: false});
            });
        }
    };

    handleSensorIdSelectChange({ target: { value } }) {
        const { locations } = this.state;
        const newLocations = locations.map(location => {
            return {
                ...location,
                selected: location.sensorId === value,
            }
        });
        this.setState({
            locations: newLocations,
        }, () => {
            this.retrieveAllData();
        });
    }

    //#endregion

    render() {
        const {classes} = this.props;

        return (
            <div className={classes.root}>
                <CssBaseline/>

                <AppBar position="absolute"
                        className={clsx(classes.appBar, this.state.drawerOpen && classes.appBarShift)}>
                    <Toolbar className={classes.toolbar}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={this.drawerDidOpen}
                            className={clsx(classes.menuButton, this.state.drawerOpen && classes.menuButtonHidden)}>
                            <MenuIcon/>
                        </IconButton>
                        <img className={classes.appBarIcon} alt="Logo" src="/apple-touch-icon.png"/>
                        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                            <b>Mobintel&trade; Platform</b>
                        </Typography>
                        <Fade in={this.state.resetFiltersEnabled}
                              style={{transitionDelay: this.state.resetFiltersEnabled ? '800ms' : '0ms'}}>
                            <Button onClick={this.onResetFiltersClick} color="inherit">Reset Filters</Button>
                        </Fade>
                        {/* <FormControlLabel
                            control={
                                <Switch checked={this.state.comparisonModeEnabled} onChange={this.onModeSwitchUpdated}
                                        value="active"/>
                            }
                            label="Comparison Mode"/> */}
                        {/* <IconButton
                            edge="end"
                            color="inherit"
                            aria-label="open map"
                            onClick={this.onMapToggle}>
                            <MapIcon/>
                        </IconButton> */}
                        <IconButton
                            edge="end"
                            color="inherit"
                            aria-label="open map"
                            component="a" href="https://about.mobintel.org" target="_blank">
                            <InfoIcon/>
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <Drawer
                    variant="temporary"
                    classes={{paper: clsx(classes.drawerPaper, !this.state.drawerOpen && classes.drawerPaperClose)}}
                    open={this.state.drawerOpen}>
                    <div className={classes.toolbarIcon}>
                        <IconButton onClick={this.drawerDidClose}>
                            <ChevronLeftIcon/>
                        </IconButton>
                    </div>
                    <Divider/>
                    <List>{mainListItems}</List>
                    <Divider/>
                    <List>{secondaryListItems}</List>
                </Drawer>

                <Drawer
                    variant="temporary"
                    classes={{paper: clsx(classes.mapPaper)}}
                    anchor="right"
                    open={this.state.mapOpen}
                    ModalProps={{onBackdropClick: this.onMapToggle}}>


                    <MapPicker
                        locations={this.state.locations}
                        onLocationUnselected={this.onMapLocationUnselected}/>

                </Drawer>

                <Fab color="secondary" className={classes.fab} href="mailto:smazokha2016@fau.edu" style={{ zIndex: 1000}}>
                    <MailIcon style={{color: 'white'}}/>
                </Fab>

                <main className={classes.content}>
                    <div className={classes.appBarSpacer}/>

                    <Container maxWidth="lg" className={classes.container}>

                        <MacDialog
                            isOpen={this.state.macModalOpen}
                            onSuccess={this.onDialogClosedSuccess}
                            onCancelled={this.onDialogClosedFailure}
                            picker={this.state.heatPicker}
                            selectedTime={this.state.selectedPopulationDate}
                            locations={this.state.locations}/>

                        <Paper className={classes.paper}>
                            <Fade in={this.state.chipsProgressEnabled}
                                  style={{transitionDelay: this.state.chipsProgressEnabled ? '800ms' : '0ms'}}>
                                <LinearProgress color="primary" className={classes.progress}/>
                            </Fade>
                            <Title>Chosen Locations</Title>
                            <ChipPanel
                                locations={this.state.locations}
                                onChipUnselected={this.onChipUnselected}
                                onChipAdd={this.onChipAdd}
                                onSensorIdSelectChange={this.handleSensorIdSelectChange}
                            />
                        </Paper>

                        <Paper className={clsx(classes.paper, classes.paperTopMargin)}>
                            <MapPickerV2
                                locations={this.state.locations}
                                onLocationUnselected={this.onMapLocationUnselected}
                            />
                        </Paper>

                        <Paper className={clsx(classes.paper, classes.paperTopMargin)}>
                            <Fade in={this.state.heatmapPlotProgressEnabled}
                                  style={{transitionDelay: this.state.heatmapPlotProgressEnabled ? '800ms' : '0ms'}}>
                                <LinearProgress color="primary" className={classes.progress}/>
                            </Fade>
                            <Title>Population Heatmap</Title>
                            <Heatmap data={this.state.heatmapPlotApiResponse?.results} startDate={this.state.heatmapPicker.startDate} />
                            <PickerBox
                                data={this.state.heatmapPicker}
                                onUpdated={this.heatmapPickerDidUpdate}
                                showHours={false}
                            />
                        </Paper>

                        <Paper className={clsx(classes.paper, classes.paperTopMargin)}>
                            <Fade in={this.state.heatProgressEnabled}
                                  style={{transitionDelay: this.state.heatProgressEnabled ? '800ms' : '0ms'}}>
                                <LinearProgress color="primary" className={classes.progress}/>
                            </Fade>
                            <Title>Population Size</Title>
                            <Chart loading={this.state.heatPlotApiResponse} yAxisTitle="Population Size, #" 
                                   apiResponse={this.state.heatPlotApiResponse}
                                   yAxisAppendix={""} onChartClick={this.onHeatChartClick} customTooltip={true}/>
                            <PickerBox
                                onUpdated={this.heatPickerDidUpdate}
                                data={this.state.heatPicker}
                                strictDatesOrder={true}
                            />
                        </Paper>

                        <Paper className={clsx(classes.paper, classes.paperTopMargin)}>
                            <Fade in={this.state.retentionProgressEnabled}
                                  style={{transitionDelay: this.state.retentionProgressEnabled ? '800ms' : '0ms'}}>
                                <LinearProgress color="primary" className={classes.progress}/>
                            </Fade>
                            <Title>Population Persistence</Title>
                            <Chart loading={this.state.retentionProgressEnabled} yAxisTitle="Population Persistence, %"
                                   apiResponse={this.state.retentionPlotApiResponse} yAxisAppendix={"%"}/>
                            <PickerBox
                                hidden={true}
                                onUpdated={this.retentionPickerDidUpdate}
                                data={this.state.retentionPicker}
                                strictDatesOrder={true}
                            />
                        </Paper>

                    </Container>

                    <Typography style={{marginBottom: "24px"}} variant="body2" color="textSecondary" align="center">
                        {'Copyright © '}<Link color="inherit" href="https://isense.fau.edu">I-SENSE, Florida Atlantic
                        University</Link>{' '}{new Date().getFullYear()}{'.'}
                    </Typography>
                </main>
            </div>
        );
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(DashboardStyles)(withUnmounted(Dashboard));
