const dev = {
    API_ROOT_ENDPOINT: 'https://api-prod.mobintel.org/v1' // 'https://api-dev.mobintel.org/v1'
};

const prod = {
    API_ROOT_ENDPOINT: 'https://api-prod.mobintel.org/v1'
};

const config = process.env.REACT_APP_STAGE === 'prod'
    ? prod
    : dev;

export default {
    // Add common config values here
    ...config
};
