import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import DateFnsUtils from '@date-io/date-fns';
import { MapControlPanelStyle, StyledValueLabel } from './mapControlPanel.style';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import PlayArrow from '@material-ui/icons/PlayArrow';
import Pause from '@material-ui/icons/Pause';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SettingsIcon from '@material-ui/icons/Settings';

export const MILLISECONDS_IN_HOUR = 3600000;


class MapControlPanel extends React.Component {
    constructor(props) {
        super(props);

        this.handleTimePickerStartChange = this.handleTimePickerStartChange.bind(this);
        this.handleTimePickerEndChange = this.handleTimePickerEndChange.bind(this);
    }

    handleTimePickerStartChange(newStartTime) {
        const { endTime, handleTimeChange } = this.props;
        let newEndTime = new Date(endTime.getTime());

        const absMaxEndTime = new Date();
        absMaxEndTime.setHours(absMaxEndTime.getHours(), 0, 0, 0);
        const absMaxStartTime = new Date(absMaxEndTime.getTime());
        absMaxStartTime.setHours(absMaxEndTime.getHours()-2, 0, 0, 0);

        // Case 1: New start date is later than 2 hours ago
        if (newStartTime > absMaxStartTime) {
            newStartTime = absMaxStartTime;
            newEndTime = absMaxEndTime;
        }

        // Case 2: New start date is earlier than 2 weeks before the current end date
        const maxTwoWeeksEndDate = new Date(newStartTime.getTime());
        maxTwoWeeksEndDate.setDate(newStartTime.getDate() + 14);
        if (endTime > maxTwoWeeksEndDate) {
            if (endTime > absMaxEndTime) {
                newEndTime = absMaxEndTime;
            } else {
                newEndTime = maxTwoWeeksEndDate;
            }
        }

        handleTimeChange(newStartTime, newEndTime);
    }

    handleTimePickerEndChange(newEndTime) {
        const { startTime, handleTimeChange } = this.props;
        let newStartTime = new Date(startTime.getTime());

        const maxDate = new Date();
        maxDate.setHours(maxDate.getHours(), 0, 0, 0);

        if (newEndTime > maxDate) {
            newEndTime = maxDate;
        }

        const maxStartDate = new Date(newEndTime.getTime());
        maxStartDate.setHours(maxStartDate.getHours() - 2, 0, 0, 0);
        if (startTime > maxStartDate)  {
            newStartTime = maxStartDate;
        }

        const minStartDate = new Date(newEndTime.getTime());
        minStartDate.setDate(newEndTime.getDate() - 14);
        if (startTime < minStartDate) {
            newStartTime = minStartDate;
        }

        handleTimeChange(newStartTime, newEndTime);
    }

    valuetext(value) {
        return moment(value).format('MMM D hA');
    }

    render() {
        const {
            classes,
            handleMaxHeatTypeChange,
            handlePlaybackSpeedChange,
            handlePlayerChange,
            handlePlayerCurrentTimestampChange,
            handleShowHeatmapSwitchChange,
            handleShowPlayerSwitchChange,
            handleShowSensorIconsSwitchChange,
            handleVirtualChange,
            includeVirtual,
            loading,
            maxHeatType,
            playbackSpeed,
            playing,
            playerCurrentTimestamp,
            showHeatmap,
            showPlayer,
            showSensorIcons,
            startTime,
            endTime,
        } = this.props;

        const startDatepickerMaxDate = new Date(endTime.getTime());
        startDatepickerMaxDate.setHours(endTime.getHours() - 2, 0, 0, 0);

        const endDatepickerMinDate = new Date(startTime.getTime());
        endDatepickerMinDate.setHours(startTime.getHours() + 2, 0, 0, 0);
        const endDatepickerMaxDate = new Date();
        endDatepickerMaxDate.setHours(endDatepickerMaxDate.getHours(), 0, 0, 0);

        return (
            <React.Fragment>
                {
                    showPlayer &&
                        <React.Fragment>
                            <Slider
                                track='normal'
                                value={playerCurrentTimestamp}
                                onChangeCommitted={handlePlayerCurrentTimestampChange}
                                style={{ paddingTop: 0 }}
                                disabled={!showHeatmap || loading}
                                defaultValue={startTime.getTime()}
                                getAriaValueText={this.valuetext}
                                aria-labelledby='discrete-slider'
                                valueLabelDisplay='auto'
                                valueLabelFormat={this.valuetext}
                                ValueLabelComponent={StyledValueLabel}
                                step={MILLISECONDS_IN_HOUR}
                                marks
                                min={startTime.getTime()}
                                max={endTime.getTime()}
                            />
                            <Grid
                                container
                                justify='flex-start'
                                alignItems='center'
                                style={{ marginLeft: 10, marginRight: 20 }}
                            >
                                <FormGroup>
                                    {
                                        playing ?
                                            <IconButton
                                                color='primary'
                                                onClick={handlePlayerChange}
                                                // variant='outlined'
                                            >
                                                <Pause/>
                                            </IconButton>
                                            :
                                            <IconButton
                                                color='primary'
                                                disabled={endTime.getTime() === playerCurrentTimestamp || !showHeatmap || loading}
                                                onClick={handlePlayerChange}
                                                // variant='outlined'
                                            >
                                                <PlayArrow/>
                                            </IconButton>
                                    }
                                </FormGroup>
                                <Typography variant="caption" display="block" gutterBottom={false}>
                                    {moment(playerCurrentTimestamp).format('ddd, MMM D, hA')}&nbsp;
                                </Typography>
                                <Typography variant="caption" display="block" gutterBottom={false}
                                            style={{ color: 'grey' }}>
                                    / {moment(endTime).format('ddd, MMM D, hA')}
                                </Typography>
                            </Grid>
                        </React.Fragment>
                }
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid
                        container
                        justify='space-between'
                        alignItems='center'
                        className={clsx(classes.settingsTopMargin, classes.settingsBottomMargin)}
                    >
                        {/*<FormGroup row style={{ marginLeft: 20 }}>
                            {
                                playing ?
                                    <Button
                                        color='primary'
                                        onClick={handlePlayerChange}
                                        variant='outlined'
                                    >
                                        <Pause/> Pause
                                    </Button>
                                    :
                                    <Button
                                        color='primary'
                                        disabled={endTime.getTime() === playerCurrentTimestamp}
                                        onClick={handlePlayerChange}
                                        variant='outlined'
                                    >
                                        <PlayArrow/> Play
                                    </Button>
                            }
                        </FormGroup>*/}

                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={showPlayer}
                                        onChange={handleShowPlayerSwitchChange}
                                        name='show-player-switch'
                                        color='secondary'
                                    />
                                }
                                label='Player'
                                labelPlacement='top'
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={showHeatmap}
                                        onChange={handleShowHeatmapSwitchChange}
                                        name='show-heatmap-switch'
                                    />
                                }
                                label='Heatmap'
                                labelPlacement='top'
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={showSensorIcons}
                                        onChange={handleShowSensorIconsSwitchChange}
                                        name='show-sensors-switch'
                                        color='secondary'
                                    />
                                }
                                label='Sensors'
                                labelPlacement='top'
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={includeVirtual}
                                        color='secondary'
                                        disabled={playing}
                                        name='include-virtual-switch'
                                        onChange={handleVirtualChange}
                                    />
                                }
                                label='Virtual Addresses'
                                labelPlacement='top'
                            />

                        </FormGroup>

                        <FormGroup row style={{ marginRight: 20 }}>
                            <DateTimePicker
                                label='Start Date &amp; Time'
                                inputVariant='outlined'
                                className={classes.pickerDefaults}
                                disabled={loading}
                                value={startTime}
                                maxDate={startDatepickerMaxDate}
                                onChange={this.handleTimePickerStartChange}
                                views={['year', 'date', 'hours']}
                                openTo='date'
                                minutesStep={60}
                            />

                            <DateTimePicker
                                label='End Date &amp; Time'
                                inputVariant='outlined'
                                className={classes.pickerDefaults}
                                disabled={!showPlayer || loading}
                                value={endTime}
                                minDate={endDatepickerMinDate}
                                maxDate={endDatepickerMaxDate}
                                onChange={this.handleTimePickerEndChange}
                                views={['year', 'date', 'hours']}
                                openTo='date'
                                minutesStep={60}
                            />

                        </FormGroup>
                        {/*<form className={classes.root} autoComplete='off' style={{ marginRight: 20 }}>
                            <FormControl variant='outlined' className={classes.pickerDefaults}>
                                <InputLabel shrink id='map-control-include-virtual-input-label'>
                                    Include Virtual Addresses
                                </InputLabel>
                                <Select
                                    disabled={loading}
                                    labelId='map-control-include-virtual-input-label'
                                    value={includeVirtual}
                                    label='Include Virtual Addresses'
                                    onChange={handleVirtualChange}
                                    inputProps={{
                                        name: 'mapControlPanelIncludeVirtual',
                                        id: 'map-control-panel-include-virtual',
                                    }}
                                >
                                    <MenuItem value={true}>True</MenuItem>
                                    <MenuItem value={false}>False</MenuItem>
                                </Select>
                            </FormControl>
                        </form>*/}
                    </Grid>
                </MuiPickersUtilsProvider>
                {
                    showPlayer &&
                    <Accordion elevation={1}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Grid
                                container
                                justify='flex-start'
                                alignItems='center'
                                style={{ color: '#676a6c' }}
                            >
                                <SettingsIcon/>&nbsp;
                                <Typography variant='overline' color='inherit'>
                                    Player Settings
                                </Typography>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <FormControl variant='outlined' className={classes.pickerDefaults}>
                                <InputLabel shrink id='map-control-speed'>
                                    Step speed
                                </InputLabel>
                                <Select
                                    disabled={loading}
                                    labelId='map-control-include-virtual-input-label'
                                    value={playbackSpeed}
                                    label='Step speed'
                                    onChange={handlePlaybackSpeedChange}
                                    inputProps={{
                                        name: 'mapControlPanelPlaybackSpeed',
                                        id: 'map-control-panel-playback-speed',
                                    }}
                                >
                                    <MenuItem value={100}>Very fast</MenuItem>
                                    <MenuItem value={300}>Fast</MenuItem>
                                    <MenuItem value={500}>Medium</MenuItem>
                                    <MenuItem value={700}>Slow</MenuItem>
                                    <MenuItem value={1000}>Very Slow</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl variant='outlined' className={classes.pickerDefaults}>
                                <InputLabel shrink id='map-control-speed'>
                                    Maximum heat type
                                </InputLabel>
                                <Select
                                    disabled={loading}
                                    labelId='map-control-max-heat-type-label'
                                    value={maxHeatType}
                                    label='Maximum heat type'
                                    onChange={handleMaxHeatTypeChange}
                                    inputProps={{
                                        name: 'mapControlPanelMaxHeatWeightType',
                                        id: 'map-control-panel-max-heat-weight-type',
                                    }}
                                >
                                    <MenuItem value='relative'>Relative for step</MenuItem>
                                    <MenuItem value='absolute'>Absolute across all steps</MenuItem>
                                </Select>
                            </FormControl>
                        </AccordionDetails>
                    </Accordion>
                }
            </React.Fragment>
        );
    }

}

MapControlPanel.propTypes = {
    loading: PropTypes.bool.isRequired,
    // Include/exclude virtual
    includeVirtual: PropTypes.bool.isRequired,
    handleVirtualChange: PropTypes.func.isRequired,

    // Player
    maxHeatType: PropTypes.string.isRequired,
    playbackSpeed: PropTypes.number.isRequired,
    playing: PropTypes.bool.isRequired,
    playerCurrentTimestamp: PropTypes.number.isRequired,
    handleMaxHeatTypeChange: PropTypes.func.isRequired,
    handlePlaybackSpeedChange: PropTypes.func.isRequired,
    handlePlayerChange: PropTypes.func.isRequired,
    handlePlayerCurrentTimestampChange: PropTypes.func.isRequired,

    // Show/hide toggles
    showHeatmap: PropTypes.bool.isRequired,
    showPlayer: PropTypes.bool.isRequired,
    showSensorIcons: PropTypes.bool.isRequired,
    handleShowHeatmapSwitchChange: PropTypes.func.isRequired,
    handleShowPlayerSwitchChange: PropTypes.func.isRequired,
    handleShowSensorIconsSwitchChange: PropTypes.func.isRequired,

    // Start/end time-pickers
    startTime: PropTypes.instanceOf(Date).isRequired,
    endTime: PropTypes.instanceOf(Date).isRequired,
    handleTimeChange: PropTypes.func.isRequired,
};

export default withStyles(MapControlPanelStyle)(MapControlPanel);
