export const MacDialogStyles = theme => ({
    windowSize: {
        maxHeight: 'calc(70% - 96px)',
        width: '400px'
    },
    itemSubtitle: {
        flex: "1 1 auto"
    },
    contentPaddings: {
        paddingLeft: '0px',
        paddingRight: '0px'
    }
});