import React from 'react';
import PropTypes from 'prop-types';
import withUnmounted from '@ishawnwang/withunmounted';
import { withStyles } from '@material-ui/core/styles';
import HeatMap from "react-heatmap-grid";
import { HeatmapStyles } from "./heatmap.style";

interface Props {
    classes?: any;
    data: Array<Array<number>>;
    startDate: Date;
}

interface State {
    heatmapMatrix: Array<Array<number>>;
    isAllZeroes: boolean;
}

const MILLISECONDS_IN_DAY = 24*60*60*1000;

const getWeekZeroMatrix = (): Array<Array<number>> => {
    const array = Array(7).fill(null).map(() => Array(24).fill(null).map(() => 0));
    return array;
};

class Heatmap extends React.Component<Props, State> {
    state: State = {
        heatmapMatrix: [],
        isAllZeroes: false,
    };

    constructor(props) {
        super(props);
        const array = getWeekZeroMatrix();
        this.state = {
            heatmapMatrix: array,
            isAllZeroes: true,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.data !== this.props.data) {
            const { startDate } = this.props;
            if (!startDate)
                return;

            const heatmapMatrix = getWeekZeroMatrix();
            let isAllZeroes = true;

            const startTs = startDate.getTime();
            const endDate = new Date(startDate);
            endDate.setDate(endDate.getDate() + 7);
            const endTs = endDate.getTime();

            this.props.data.forEach(dataPoint => {
                // try-catch in case there is a data-point outside of indices of the matrix
                try {
                    if (dataPoint && dataPoint.length > 1) {
                        const date = new Date(dataPoint[0]);
                        if (date.getTime() >= startTs && date.getTime() <= endTs) {
                            const day = Math.floor((date.getTime() - startTs)/MILLISECONDS_IN_DAY);
                            heatmapMatrix[day][date.getHours()] = dataPoint[1];
                            if (dataPoint[1] !== 0) {
                                isAllZeroes = false;
                            }
                        }
                    }
                } catch (e) {
                    const date = new Date(dataPoint[0]);
                    // const day = date.getDate() - startDate.getDate();
                    // const hour = date.getHours();

                    // console.log('ERROR: heatmap data mapping: date, day, hour, e', date, day, hour, e);
                }
            });
            this.setState({
                heatmapMatrix,
                isAllZeroes,
            });
        }
    }

    getWeekLabels(startDate: Date) {
        const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        let start = startDate.getDay(); //gets day number
        if (start == 0) {
            // if Sunday, days are already in order
            return days;
        } else {
            // if not Sunday, start days with today
            return days.slice(start).concat(days.slice(0,start));
        }
    }

    getHourLabels() {
        return new Array(24).fill(0).map((_, hour) => {
            const ampm = hour >= 12 ? 'pm' : 'am';
            let formattedHour = hour % 12;
            formattedHour = formattedHour ? formattedHour : 12; // the hour '0' should be '12'
            return `${formattedHour} ${ampm}`;
        });
    }

    render() {
        const { startDate } = this.props;
        const { heatmapMatrix, isAllZeroes } = this.state;
        console.log('DEBUG: heatmapMatrix is ', heatmapMatrix);
        const xLabels = this.getHourLabels();

        // Display only even labels
        const xLabelsVisibility = new Array(24)
            .fill(0)
            .map((_, i) => (i % 2 === 0));

        const yLabels = this.getWeekLabels(startDate);

        return (
            <div style={{ marginTop: 20, marginLeft: 30, position: 'relative', overflow: 'auto' }}>
                <div style={{ fontSize: '13px' }}>
                    <HeatMap
                        xLabels={xLabels}
                        yLabels={yLabels}
                        xLabelsLocation='bottom'
                        xLabelsVisibility={xLabelsVisibility}
                        xLabelWidth={60}
                        data={heatmapMatrix}
                        squares
                        height={45}
                        // onClick={(x, y) => alert(`Clicked ${x}, ${y}`)} // leaving it here in case action is required in the future
                        cellStyle={(background, value, min, max, data, x, y) => {
                            return isAllZeroes ? {
                                background: 'rgb(255, 152, 0, 0)',
                                fontSize: "11.5px",
                                color: "#444"
                            } : {
                                background: `rgb(255, 152, 0, ${1 - (max - value) / (max - min)})`,
                                fontSize: "11.5px",
                                color: "#444"
                            }
                        }}
                        cellRender={value => value && <div>{value}</div>}
                    />
                </div>
            </div>
        );
    }
}

HeatMap.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.array.isRequired,
    startDate: PropTypes.object.isRequired,
};

export default withStyles(HeatmapStyles)(withUnmounted(Heatmap));
