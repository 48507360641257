import React from 'react';
import PropTypes from 'prop-types';
import withUnmounted from '@ishawnwang/withunmounted';
import { MapPickerV2Styles } from './mappicker-v2.style';
import Fade from '@material-ui/core/Fade';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import MapControlPanel, { MILLISECONDS_IN_HOUR } from './mapControlPanel/mapControlPanel.component';
import CustomMapsWrapper from './customMapsWrapper/customMapsWrapper.component';
import Title from "../title/title.component";
import { getHeatmapDataApi } from "../../api/data.api";

const STEP_DURATION_MILLISECONDS = 100;
const ABSOLUTE_HEAT_MAX_TYPE = 'absolute';
const RELATIVE_HEAT_MAX_TYPE = 'relative';

export class MapPickerV2 extends React.Component {
    constructor(props) {
        super(props);

        const startDate = new Date();
        startDate.setHours(startDate.getHours() - 100, 0, 0, 0);
        const endDate = new Date();
        endDate.setHours(endDate.getHours(), 0, 0, 0);

        this.state = {
            intervalId: null,
            loading: true,
            currentLoadingProgress: 0,

            // Heatmap data
            useAbsoluteMax: true,
            maxWeightDataPoint: {lat: 90, lng: 135, weight: 0},
            computedHeatmapData: [],
            rawHeatmapData: [],
            computedHeatmapDataPerTs: {},
            rawHeatmapDataPerTs: {},

            // Include/exclude virtual controls
            includeVirtual: true,

            // Player controls
            playbackSpeed: STEP_DURATION_MILLISECONDS,
            playing: false,
            playerCurrentTimestamp: startDate.getTime(),

            // Toggles controls
            showPlayer: true,
            showHeatmap: true,
            showSensorIcons: true,

            // Time-pickers controls
            startTime: startDate,
            endTime: endDate,
        };

        this.getHeatmapDataForDatePeriod = this.getHeatmapDataForDatePeriod.bind(this);
        this.getHeatmapDataOnMapLoad = this.getHeatmapDataOnMapLoad.bind(this);
        this.handleMaxHeatTypeChange = this.handleMaxHeatTypeChange.bind(this);

        this.handlePlaybackSpeedChange = this.handlePlaybackSpeedChange.bind(this);
        this.handlePlayerChange = this.handlePlayerChange.bind(this);
        this.handlePlayerCurrentTimestampChange = this.handlePlayerCurrentTimestampChange.bind(this);

        this.handleTimeChange = this.handleTimeChange.bind(this);

        this.handleVirtualChange = this.handleVirtualChange.bind(this);

        this.handleShowHeatmapSwitchChange = this.handleShowHeatmapSwitchChange.bind(this);
        this.handleShowPlayerSwitchChange = this.handleShowPlayerSwitchChange.bind(this);
        this.handleShowSensorIconsSwitchChange = this.handleShowSensorIconsSwitchChange.bind(this);

        this.incrementCurrentPlayerTimestamp = this.incrementCurrentPlayerTimestamp.bind(this);

        this.setLoading = this.setLoading.bind(this);
    }

    computeHeatmapData(rawHeatmapData, includeVirtual) {
        let max = 0;

        const computedHeatmapData = rawHeatmapData.map(el => {
            const { location, countAll, countPhy } = el;
            const weight = includeVirtual ? countAll : countPhy
            max = max < weight ? weight : max;
            return { ...location, weight };
        });
        return {
            computedHeatmapData,
            max
        }
    };

    async getHeatmapDataForDatePeriod(startTime, endTime) {
        const { maxWeightDataPoint, includeVirtual } = this.state;
        this.setState({ loading: true, currentLoadingProgress: 0 });

        const allTimestamps = [];
        for (let i = startTime.getTime(); i <= endTime.getTime(); i += MILLISECONDS_IN_HOUR) {
            allTimestamps.push(i);
        }
        const rawHeatmapDataPerTs = {};
        const computedHeatmapDataPerTs = {};
        const oneResponsePercentWeight = (1/allTimestamps.length)*100;
        let maxPerTs = {};

        await Promise.all(allTimestamps.map(ts => {
            return getHeatmapDataApi(ts).then((response) => {
                const { currentLoadingProgress } = this.state;
                const { errorCode, results } = response;
                const rawHeatmapData = results?.[0];

                if (errorCode === 0 && rawHeatmapData?.length > 0) {
                    const { computedHeatmapData, max } = this.computeHeatmapData(rawHeatmapData, includeVirtual);
                    rawHeatmapDataPerTs[ts] = rawHeatmapData;
                    computedHeatmapDataPerTs[ts] = computedHeatmapData;
                    maxPerTs[ts] = max;
                } else {
                    rawHeatmapDataPerTs[ts] = [];
                    computedHeatmapDataPerTs[ts] = [];
                    maxPerTs[ts] = 0;
                }
                this.setState({ currentLoadingProgress: currentLoadingProgress + oneResponsePercentWeight });
            });
        }));

        const maxWeight = Math.max( ...Object.values(maxPerTs) );

        this.setState({
            loading: false,
            currentLoadingProgress: 0,
            maxWeightDataPoint: { ...maxWeightDataPoint, weight: maxWeight },
            rawHeatmapDataPerTs,
            computedHeatmapDataPerTs,
        })
    }

    getHeatmapDataOnMapLoad() {
        const { startTime, endTime } = this.state;
        this.getHeatmapDataForDatePeriod(startTime, endTime);
    }

    handleMaxHeatTypeChange() {
        const { useAbsoluteMax } = this.state;
        this.setState({ useAbsoluteMax: !useAbsoluteMax });
    }

    fixCurrentTimestampIfNeeded(startTime, endTime) {
        const { playerCurrentTimestamp } = this.state;
        if (startTime && playerCurrentTimestamp < startTime.getTime()) {
            return startTime.getTime();
        } else if (endTime && playerCurrentTimestamp > endTime.getTime()) {
            return endTime.getTime();
        }
        return playerCurrentTimestamp;
    }

    handleTimeChange(startTime, endTime) {
        startTime.setHours(startTime.getHours(), 0, 0, 0);
        endTime.setHours(endTime.getHours(), 0, 0, 0);
        const playerCurrentTimestamp = this.fixCurrentTimestampIfNeeded(startTime, endTime)
        this.setState({ startTime, endTime, loading: true, playerCurrentTimestamp });
        this.getHeatmapDataForDatePeriod(startTime, endTime);
    }

    handleVirtualChange() {
        const { includeVirtual } = this.state;
        const newIncludeVirtual = !includeVirtual;
        const { rawHeatmapDataPerTs, maxWeightDataPoint } = this.state;
        const computedHeatmapDataPerTs = {};
        let maxPerTs = {};

        if (rawHeatmapDataPerTs) {
            this.setState({ loading: true, currentLoadingProgress: 0 });

            const allTimestamps = Object.keys(rawHeatmapDataPerTs);
            const oneResponsePercentWeight = (1/allTimestamps.length)*100;
            let currRawData;

            allTimestamps.forEach(ts => {
                const { currentLoadingProgress } = this.state;
                currRawData = rawHeatmapDataPerTs[ts];

                if (currRawData) {
                    const { computedHeatmapData, max } = this.computeHeatmapData(currRawData, newIncludeVirtual);
                    computedHeatmapDataPerTs[ts] = computedHeatmapData;
                    maxPerTs[ts] = max;
                } else {
                    computedHeatmapDataPerTs[ts] = [];
                    maxPerTs[ts] = 0;
                }
                this.setState({ currentLoadingProgress: currentLoadingProgress + oneResponsePercentWeight });
            });
        }

        const maxWeight = Math.max(...Object.values(maxPerTs));

        this.setState({
            computedHeatmapDataPerTs,
            currentLoadingProgress: 0,
            includeVirtual: newIncludeVirtual,
            loading: false,
            maxWeightDataPoint: { ...maxWeightDataPoint, weight: maxWeight },
        });
    }

    setLoading(loading) {
        this.setState({ loading });
    }

    handlePlayerChange() {
        const { playing, intervalId, playbackSpeed } = this.state;
        const shouldStartPlaying = !playing;
        let newIntervalId = null;
        if (shouldStartPlaying) {
            if (!intervalId) {
                newIntervalId = window.setInterval(() => {
                    this.incrementCurrentPlayerTimestamp();
                }, playbackSpeed);
            }
        } else {
            if (intervalId) {
                window.clearInterval(intervalId);
            }
        }
        this.setState({ playing: shouldStartPlaying, intervalId: newIntervalId });
    }

    handlePlaybackSpeedChange({ target: { value: playbackSpeed } }) {
        const { intervalId } = this.state;
        let newIntervalId = null;
        if (intervalId) {
            window.clearInterval(intervalId);
            newIntervalId = window.setInterval(() => {
                this.incrementCurrentPlayerTimestamp();
            }, playbackSpeed);
        }
        this.setState({
            intervalId: newIntervalId,
            playbackSpeed,
        })
    }

    handlePlayerCurrentTimestampChange(event, value) {
        this.incrementCurrentPlayerTimestamp(value);
    }

    incrementCurrentPlayerTimestamp(newTimestamp) {
        const { playerCurrentTimestamp, endTime, intervalId } = this.state;
        newTimestamp = newTimestamp ?? playerCurrentTimestamp + MILLISECONDS_IN_HOUR;
        if (newTimestamp < endTime.getTime()) {
            this.setState({
                playerCurrentTimestamp: newTimestamp,
            })
        } else {
            if (intervalId) {
                window.clearInterval(intervalId);
            }
            this.setState({
                intervalId: null,
                playerCurrentTimestamp: endTime.getTime(),
                playing: false,
            });

        }
    }

    handleShowHeatmapSwitchChange() {
        const { showHeatmap, intervalId } = this.state;
        if (intervalId) {
            window.clearInterval(intervalId);
        }
        this.setState({ showHeatmap: !showHeatmap, intervalId: null, playing: false });
    }

    handleShowPlayerSwitchChange() {
        const { showPlayer, intervalId } = this.state;
        if (intervalId) {
            window.clearInterval(intervalId);
        }
        this.setState({ showPlayer: !showPlayer, intervalId: null, playing: false });
    }

    handleShowSensorIconsSwitchChange() {
        const { showSensorIcons } = this.state;
        this.setState({ showSensorIcons: !showSensorIcons });
    }

    render() {
        const {
            classes,
            locations,
            onLocationUnselected,
        } = this.props;
        const {
            loading,
            currentLoadingProgress,

            // Heatmap data
            useAbsoluteMax,
            maxWeightDataPoint,
            computedHeatmapDataPerTs,

            // Include/exclude virtual controls
            includeVirtual,

            // Player controls
            playbackSpeed,
            playing,
            playerCurrentTimestamp,

            // Toggles controls
            showHeatmap,
            showPlayer,
            showSensorIcons,

            // Time-pickers controls
            startTime,
            endTime,
        } = this.state;

        let heatmapData = [];
        if (playerCurrentTimestamp in computedHeatmapDataPerTs) {
            const heatmapDataForCurrentTs = computedHeatmapDataPerTs[playerCurrentTimestamp]
            heatmapData = useAbsoluteMax ? [...heatmapDataForCurrentTs, maxWeightDataPoint] : heatmapDataForCurrentTs;
        }

        const customMapsWrapperProps = {
            // Map locations
            locations,
            onLocationUnselected,

            // Heatmap data
            heatmapData,
            getHeatmapData: this.getHeatmapDataOnMapLoad,

            // Show/hide settings
            showHeatmap,
            showSensorIcons,
        }

        const mapControlProps = {
            loading,
            // Include/exclude virtual
            includeVirtual,
            handleVirtualChange: this.handleVirtualChange,

            // Player
            maxHeatType: useAbsoluteMax ? ABSOLUTE_HEAT_MAX_TYPE : RELATIVE_HEAT_MAX_TYPE,
            playbackSpeed,
            playing,
            playerCurrentTimestamp,
            handleMaxHeatTypeChange: this.handleMaxHeatTypeChange,
            handlePlaybackSpeedChange: this.handlePlaybackSpeedChange,
            handlePlayerChange: this.handlePlayerChange,
            handlePlayerCurrentTimestampChange: this.handlePlayerCurrentTimestampChange,

            // Show/hide toggles
            showHeatmap,
            showPlayer,
            showSensorIcons,
            handleShowHeatmapSwitchChange: this.handleShowHeatmapSwitchChange,
            handleShowPlayerSwitchChange: this.handleShowPlayerSwitchChange,
            handleShowSensorIconsSwitchChange: this.handleShowSensorIconsSwitchChange,

            // Start/end time-pickers
            startTime,
            endTime,
            handleTimeChange: this.handleTimeChange,
        }

        return (
            <React.Fragment>
                <Fade
                    in={loading}
                    style={{ transitionDelay: loading ? '800ms' : '0ms' }}
                >
                    <LinearProgress
                        className={classes.progress}
                        color='primary'
                        value={currentLoadingProgress}
                        variant='determinate'
                    />
                </Fade>

                <Title>Heatmap</Title>

                <div style={{ marginTop: 10 }}>
                    <CustomMapsWrapper {...customMapsWrapperProps}/>
                </div>

                <MapControlPanel {...mapControlProps}/>
            </React.Fragment>
        );
    }
}

MapPickerV2.propTypes = {
    classes: PropTypes.object.isRequired,
    locations: PropTypes.array.isRequired,
    onLocationUnselected: PropTypes.func.isRequired
};

export default withStyles(MapPickerV2Styles)(withUnmounted(MapPickerV2));
