import React from 'react';
import PropTypes from 'prop-types';
import withUnmounted from '@ishawnwang/withunmounted';
import { CustomMapsWrapperStyle } from './customMapsWrapper.style';
import { withStyles } from '@material-ui/core/styles';
import './customMapsWrapper.styles.css';
import GoogleMapReact from 'google-map-react';


const Marker = ({ imageSrc, onClick }) => (
    <img
        alt='sensor'
        onClick={() => onClick()}
        src={imageSrc}
        style={{ cursor: 'pointer' }}
        height='30'
        width='30'
    />
);

export class CustomMapsWrapper extends React.Component {
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };
    _googleMap;

    constructor(props) {
        super(props);

        this.state = {
            heatmapVisible: true,
            locations: props.locations,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let newLocations;
        const { locations, showHeatmap } = this.props;

        if (prevProps.locations !== locations) {
            newLocations = locations.map((location, sensorIndex) => ({
                ...location,
                sensorIndex,
            }));
        }

        if (prevProps.showHeatmap !== showHeatmap) {
            this.toggleHeatmap(showHeatmap);
        }

        if (newLocations) {
            this.setState({
                locations: newLocations,
            });
        }
    }

    onMarkerClick = (position) => {
        const { locations } = this.state;
        const { onLocationUnselected } = this.props;
        try {
            // copying the list of locations from the filtered list & updating the selected item's status
            const updatedLocations = locations.map(location => ({
                ...location,
                selected: false,
            }));
            updatedLocations[position].selected = true;

            // updating the state
            onLocationUnselected(updatedLocations);
        } catch (e) {
            console.log(`ERROR: failed to get the position marker: ${position}, total markers count: ${locations?.length}`)
        }

    };

    toggleHeatmap(heatmapVisible) {
        if (this._googleMap !== undefined) {
            this._googleMap.heatmap.setMap(heatmapVisible ? this._googleMap.map_ : null)
        }
    }

    render() {
        const { showSensorIcons, heatmapData, getHeatmapData } = this.props;

        const markers = this.state.locations.filter(({ selected }) => !selected).map((location) => {
            const { sensorId, location: { x, y }, sensorIndex } = location;
            return (
                <Marker
                    key={sensorId}
                    lat={x}
                    lng={y}
                    imageSrc='marker_inactive.png'
                    onClick={this.onMarkerClick.bind(this, sensorIndex)}
                />
            )
        }).concat(this.state.locations.filter(({ selected }) => selected).map((location) => {
            const { sensorId, location: { x, y }, sensorIndex } = location;
            return (
                <Marker
                    key={sensorId}
                    lat={x}
                    lng={y}
                    imageSrc='marker.png'
                    onClick={this.onMarkerClick.bind(this, sensorIndex)}
                />
            )
        }));

        return (
            <div id='mapBox'>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: "AIzaSyAFjJ5fDj8Se3xC8T4PGVBB7cFinvq_K5A", libraries: ['localContext'] }}
                    defaultCenter={{ lat: 26.713212, lng: -80.051584 }}
                    defaultZoom={18}
                    heatmapLibrary={true}
                    heatmap={{
                        positions: heatmapData,
                        options: {
                            radius: 50,
                            // opacity: 0.6
                        }
                    }}
                    layerTypes={['TrafficLayer', 'TransitLayer']}
                    onGoogleApiLoaded={({ map, maps }) => getHeatmapData(map, maps)}
                    ref={(el) => this._googleMap = el}
                    yesIWantToUseGoogleMapApiInternals
                >
                    {showSensorIcons && markers}
                </GoogleMapReact>
            </div>
        );
    }
}

CustomMapsWrapper.propTypes = {
    classes: PropTypes.object.isRequired,

    // Map locations
    locations: PropTypes.array.isRequired,
    onLocationUnselected: PropTypes.func.isRequired,

    // Heatmap data
    heatmapData: PropTypes.array.isRequired,
    getHeatmapData: PropTypes.func.isRequired,

    // Show/hide settings
    showHeatmap: PropTypes.bool.isRequired,
    showSensorIcons: PropTypes.bool.isRequired,
};

export default withStyles(CustomMapsWrapperStyle)(withUnmounted(CustomMapsWrapper));
