import React from 'react';
import PropTypes from 'prop-types';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import AddIcon from '@material-ui/icons/Add';
import withUnmounted from '@ishawnwang/withunmounted';
import { ChipPanelStyles } from './chippanel.styles';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

class ChipPanel extends React.Component {

    hasUnmounted = false;
    state = {};

    constructor(props) {
        super(props);
        this.state = {
            locations: props.locations // example: {"sensorId":1,"name":"MobintelSensor1","location":{"x":26.713412,"y":-80.053584}}
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.locations !== this.props.locations)
            this.setState({ locations: this.props.locations });
    }

    onChipDeleted = (position) => {
        const newLocations = this.state.locations.slice(0);
        newLocations[position].selected = false;
        this.props.onChipUnselected(this.state.locations);
    }

    getSelectedLocationsSize = () => {
        var size = 0;
        for (var i = 0; i < this.state.locations.length; i++)
            if (this.state.locations[i].selected)
                size++;
        return size;
    }

    render() {
        const { classes } = this.props;
        const selectedLocation = this.state.locations.find(location => location.selected);

        return (
            <div>
                <div className={classes.chipContainer}>
                    {this.state.locations.map((location, position) => {
                        if (location.selected)
                            return <Chip
                                key={location.sensorId}
                                icon={<LocationOnIcon color={classes.chipIcon} />}
                                className={classes.chip}
                                label={location.label}
                                // onDelete={this.onChipDeleted.bind(this, position)}
                                color="primary" />;
                        else return null;
                    })}
                    {
                        (this.getSelectedLocationsSize() < this.state.locations.length) ?
                            (<Chip
                                icon={<AddIcon color={classes.chipIcon} />}
                                className={classes.chip}
                                onClick={this.props.onChipAdd}
                                label="Choose on map" />) : (null)
                    }
                </div>
                <div className={classes.sensorIdSelectContainer}>
                    <div>
                        {
                            selectedLocation
                            && (
                                <FormControl className={classes.sensorIdFormControl}>
                                    <InputLabel>Sensor ID</InputLabel>
                                    <Select
                                        MenuProps={{
                                            style: {zIndex: 35001},
                                        }}
                                        value={selectedLocation.sensorId}
                                        onChange={this.props.onSensorIdSelectChange}
                                    >
                                        {
                                            this.state.locations.map(location => (
                                                <MenuItem
                                                    key={`chip-container__sensor-select${location.sensorId}`}
                                                    value={location.sensorId}
                                                >
                                                    {location.label}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            )
                        }
                    </div>

                </div>
            </div>
        );
    }
}

ChipPanel.propTypes = {
    classes: PropTypes.object.isRequired,
    locations: PropTypes.array.isRequired,
    onChipUnselected: PropTypes.func.isRequired,
    onChipAdd: PropTypes.func.isRequired,
    onSensorIdSelectChange: PropTypes.func.isRequired,
};

export default withStyles(ChipPanelStyles)(withUnmounted(ChipPanel));
