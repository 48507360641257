import { withStyles } from "@material-ui/core/styles";
import ValueLabel from "@material-ui/core/Slider/ValueLabel";

export const MapControlPanelStyle = theme => ({
    root: {
        display: 'flex'
    },
    settingsTopMargin: {
        marginTop: 24
    },
    settingsBottomMargin: {
        marginBottom: 24
    },
    snackbarIcon: {
        fontSize: 20,
    },
    snackbarIconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    snackbarMessage: {
        display: 'flex',
        alignItems: 'center',
    },
    pickerDefaults: {
        margin: "6px",
        width: "270px"
    }
});

export const StyledValueLabel = withStyles({
    circle: {
        width: 60,
        height: 60,
    },
    offset: {
        top: -65,
        left: -24,
    },
    label: {
        paddingLeft: 11,
    }
})(ValueLabel);
