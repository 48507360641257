import config from '../config';

const API_REQUEST_HEAT = '/deviceCounts';
const API_REQUEST_RETENTION = '/retentionPlot';
const API_REQUEST_HEAT_POPULATION = '/heatPopulation';
const API_REQUEST_SENSORS = '/sensors';
const API_REQUEST_HEATMAP = '/heatmap';

export const getHeatData = (startTime, endTime, density, sensorId, includeVirtual, includeMLData) => {
    const body = {startTime, endTime, density, sensorId, includeVirtual, includeMLData};
    return apiRequest(body, config.API_ROOT_ENDPOINT + API_REQUEST_HEAT, 'POST');
};

export const getRetentionData = (locationId, startTime, includeVirtual) => {
    const body = {startTime, includeVirtual, locationId};
    return apiRequest(body, config.API_ROOT_ENDPOINT + API_REQUEST_RETENTION, 'POST');
};

export const getHeatPopulationData = (startTime, density, locationIds, includeVirtual) => {
    const body = {startTime, density, locationIds, includeVirtual};
    return apiRequest(body, config.API_ROOT_ENDPOINT + API_REQUEST_HEAT_POPULATION, 'POST');
};

export const getSensors = () => {
    return apiRequest({}, config.API_ROOT_ENDPOINT + API_REQUEST_SENSORS, 'GET');
};

export const getHeatmapDataApi = (startTime) => {
    const body = {startTime};
    return apiRequest(body, config.API_ROOT_ENDPOINT + API_REQUEST_HEATMAP, 'POST');
};

const apiRequest = (body, url, requestType) => new Promise((resolve, reject) => {
    try {
        let xhttp = new XMLHttpRequest();
        xhttp.open(requestType, url, true);
        xhttp.onreadystatechange = function () {
            if (xhttp.readyState === 4 && xhttp.status === 200) {
                const response = JSON.parse(xhttp.responseText);
                // console.log('response ', response);
                if ('errorCode' in response && response.errorCode === 0) {
                    resolve(response);
                } else {
                    reject(response);
                }
            } else if (xhttp.readyState === 4) {
                const response = JSON.parse(xhttp.responseText);
                reject(response);
            }
        };
        xhttp.send(JSON.stringify(body));
    }
    catch (reqError) {
        reject(reqError);
    }
    
});
