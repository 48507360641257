export const ChipPanelStyles = theme => ({
    chipPaper: {
        display: 'flex',
        flexDirection: 'column',
        padding: '24px'
    },
    chipContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        padding: theme.spacing(1)
    },
    chip: {
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
        marginBottom: theme.spacing(2)
    },
    sensorIdFormControl: {
        minWidth: 250,
    },
    sensorIdSelectContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        marginBottom: theme.spacing(3),
    },
});
