import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import DateFnsUtils from '@date-io/date-fns';
import WarningIcon from '@material-ui/icons/Warning';
import { PickerBoxStyles } from './pickerbox.style';
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

/**
 * Properties:
 * - data: settings of the picker
 * - onUpdated: callback for the box updates
 */
class PickerBox extends React.Component {

    state = {};

    constructor(props) {
        super(props);
        this.state = {
            onUpdatedCallback: props.onUpdated,
            pickerDensity: props.data.density,
            pickerDensityBlocked: props.data.densityBlocked,
            pickerIncludeVirtual: props.data.includeVirtual,
            pickerStartDate: props.data.startDate,
            pickerEndDate: props.data.endDate,
            snackbarIsOpen: false,
            snackbarMessage: ''
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data.density !== this.state.pickerDensity)
            this.setState({ pickerDensity: this.props.data.density });
        if (prevProps.data.densityBlocked !== this.state.pickerDensityBlocked)
            this.setState({ pickerDensityBlocked: this.props.data.densityBlocked });
        if (prevProps.data.includeVirtual !== this.state.pickerIncludeVirtual)
            this.setState({ pickerIncludeVirtual: this.props.data.includeVirtual });
        if (prevProps.data.startDate !== this.state.pickerStartDate)
            this.setState({ pickerStartDate: this.props.data.startDate });
        if (prevProps.data.endDate !== this.state.pickerEndDate)
            this.setState({ pickerEndDate: this.props.data.endDate });
    }

    onSnackbarClose = () => {
        this.setState({
            snackbarIsOpen: false
        });
    }

    didStartDateChange = (date) => {
        const { strictDatesOrder } = this.props;
        let error = false;
        let errorMessage = '';

        if (strictDatesOrder && date > this.state.pickerEndDate) {
            error = true;
            errorMessage = 'Selected start date & time must always be smaller than the end date & time.';
        } else this.setState({ pickerStartDate: date }, () => this.sendUpdate());

        if (error) this.setState({
            snackbarIsOpen: true,
            snackbarMessage: errorMessage
        });
    }

    didEndDateChange = (date) => {
        const { strictDatesOrder } = this.props;
        var now = new Date();

        var error = false;
        var errorMessage = '';

        if (strictDatesOrder && date > now) {
            error = true;
            errorMessage = 'Selected end date & time cannot be later in the future.';
        } else if (strictDatesOrder && date < this.state.pickerStartDate) {
            error = true;
            errorMessage = 'Selected end date & time must always be larger than the start date & time.'
        } else this.setState({ pickerEndDate: date }, () => this.sendUpdate());

        if (error) this.setState({
            snackbarIsOpen: true,
            snackbarMessage: errorMessage
        });
    };

    didDensityChange = (event) => {
        this.setState({ pickerDensity: event.target.value }, () => this.sendUpdate());
    };

    didIncludeVirtualChange = (event) => {
        this.setState({ pickerIncludeVirtual: event.target.value }, () => this.sendUpdate());
    }

    sendUpdate() {
        if (this.state.onUpdatedCallback != null)
            this.state.onUpdatedCallback(
                this.state.pickerDensity,
                this.state.pickerIncludeVirtual,
                this.state.pickerStartDate,
                this.state.pickerEndDate
            );
    }

    render() {
        const { classes } = this.props;
        const maxDate = new Date();
        if (this.props.data?.strictPeriodLengthInDays !== undefined) {
            maxDate.setDate(maxDate.getDate() - this.props.data?.strictPeriodLengthInDays);
        }
        if (!this.props.hidden)
            return (
                <React.Fragment>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        autoHideDuration={5000}
                        disableWindowBlurListener={true}
                        open={this.state.snackbarIsOpen}
                        onClose={this.onSnackbarClose}>
                        <SnackbarContent
                            style={{ backgroundColor: '#FFA000', zIndex: 1000, marginTop: 70, }} message={
                                <span id="client-snackbar" className={classes.snackbarMessage}>
                                    <WarningIcon className={clsx(classes.snackbarIcon, classes.snackbarIconVariant)} />
                                    {this.state.snackbarMessage}
                                </span>
                            } />
                    </Snackbar>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justify="center" className={clsx(classes.settingsTopMargin, classes.settingsBottomMargin)}>
                            {
                                this.props.showHours === false
                                    ? (
                                        <React.Fragment>
                                            <DatePicker
                                                label="Start Date &amp; Time"
                                                inputVariant="outlined"
                                                className={classes.pickerDefaults}
                                                value={this.state.pickerStartDate}
                                                maxDate={maxDate}
                                                onChange={this.didStartDateChange} />
                                            <DatePicker
                                                disabled={this.props.data?.strictPeriodLengthInDays !== undefined}
                                                label="End Date &amp; Time"
                                                inputVariant="outlined"
                                                className={classes.pickerDefaults}
                                                value={this.state.pickerEndDate}
                                                maxDate={new Date()}
                                                onChange={this.didEndDateChange} />
                                        </React.Fragment>
                                    )
                                    : (
                                        <React.Fragment>
                                            <DateTimePicker
                                                label="Start Date &amp; Time"
                                                inputVariant="outlined"
                                                className={classes.pickerDefaults}
                                                value={this.state.pickerStartDate}
                                                maxDate={maxDate}
                                                onChange={this.didStartDateChange} />
                                            <DateTimePicker
                                                disabled={this.props.data?.strictPeriodLengthInDays !== undefined}
                                                label="End Date &amp; Time"
                                                inputVariant="outlined"
                                                className={classes.pickerDefaults}
                                                value={this.state.pickerEndDate}
                                                maxDate={new Date()}
                                                onChange={this.didEndDateChange} />
                                        </React.Fragment>
                                    )
                            }

                            <form className={classes.root} autoComplete="off">
                                <FormControl variant="outlined" className={classes.pickerDefaults}>
                                    <Select
                                        disabled={this.state.pickerDensityBlocked}
                                        value={this.state.pickerDensity}
                                        onChange={this.didDensityChange}
                                        inputProps={{
                                            name: 'pickerDensity',
                                            id: 'outlined-density',

                                        }}>
                                        <MenuItem value={5}>Density: 5 mins</MenuItem>
                                        <MenuItem value={60}>Density: 1 hr</MenuItem>
                                        <MenuItem value={360}>Density: 6 hrs</MenuItem>
                                        <MenuItem value={720}>Density: 12 hrs</MenuItem>
                                        <MenuItem value={1440}>Density: 1 day</MenuItem>
                                    </Select>
                                </FormControl>
                            </form>

                            <form className={classes.root} autoComplete="off">
                                <FormControl variant="outlined" className={classes.pickerDefaults}>
                                    <Select
                                        value={this.state.pickerIncludeVirtual}
                                        onChange={this.didIncludeVirtualChange}
                                        inputProps={{
                                            name: 'pickerIncludeVirtual',
                                            id: 'outlined-include-virtual',
                                        }}>
                                        <MenuItem value={true}>Include Virtual Addresses: True</MenuItem>
                                        <MenuItem value={false}>Include Virtual Addresses: False</MenuItem>
                                    </Select>
                                </FormControl>
                            </form>
                        </Grid>
                    </MuiPickersUtilsProvider>
                </React.Fragment>
            )
        return null;
    }

}

PickerBox.propTypes = {
    onUpdated: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    showHours: PropTypes.bool,
    strictDatesOrder: PropTypes.bool,
};

export default withStyles(PickerBoxStyles)(PickerBox);
